// *Design Imports*
import { Text, chakra } from "@chakra-ui/react";

// *Redux Imports*
import { useSelector } from "react-redux";

const GetBalance = (props) => {
  const { my_money } = useSelector((state) => state.save);


  return (
    <Text aria-label="Balance" variant="blackjack" {...props}>
      {/* {console.log(my_money)} */}
      Balance:{" "}
      <chakra.span color={my_money === 0 ? "r500" : "g500"} fontWeight="500">
        {my_money === null ? "$0" : `$${my_money}`}
      </chakra.span>
    </Text>
  );
};

export default GetBalance;
