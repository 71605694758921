import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import AkkhorLayout from "../layout/akkhor";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import AlertErrorForm from "../component/AlertErrorForm";
import { confirmAlert } from "react-confirm-alert";
import * as Yup from "yup";
import Spinner from "../component/Spinner";
import backgroud from "../assets/images/Bg.jpg"

export default function WithDraw() {
    const { user } = useSelector((state) => state.auth);
    const [userInfo, setUserInfo] = useState(null)
    const [loading, setLoading] = useState(false);
    const [webSeting, setWebSeting] = useState(false);
    const [valueWithdraw, setValueWithdraw] = useState(0);

    const defaultdetail = {
        username: '', mobile: '', fullname: '', bankname: '', banknumber: '', bankaddress: '', status: 1
    }
    useEffect(() => {
        if (user) {
            Axios.get(URL_LIST_ALL + 'users/' + user.data.id, user.config).then((res) => {
                if (res.data.status === 'success') {
                    setUserInfo(res.data.data)
                } else {
                    console.log(res.data.message);
                }
            }).catch((err) => console.log(err));
            Axios.get(URL_LIST_ALL + 'banks_settings', user.config).then((res) => {
                if (res.data.status === 'success') {
                    setWebSeting(res.data.data)
                } else {
                    console.log(res.data.message);
                }
            }).catch((err) => console.log(err));
        }
    }, [])
    const formik = useFormik({
        initialValues: { ...defaultdetail, ...userInfo },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            mobile: Yup.string().trim().nullable().required('ກະລຸນາໃສ່ໂທລະສັບມືຖືຂອງທ່ານ'),
            fullname: Yup.string().trim().nullable().required('ກະລຸນາໃສ່ຊື່ເຕັມຂອງເຈົ້າ'),
            bankname: Yup.string().trim().nullable().required('ກະລຸນາໃສ່ຊື່ທະນາຄານ'),
            banknumber: Yup.string().trim().nullable().required('ກະລຸນາໃສ່ໝາຍເລກທະນາຄານ'),
            bankaddress: Yup.string().trim().nullable().required('ກະລຸນາໃສ່ທີ່ຢູ່ທະນາຄານ'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (user.data.id > 0) {
                Axios.put(URL_LIST_ALL + 'users/' + user.data.id, values, user.config).then(res => {
                    if (res.data.status === "success") {
                        resetForm();
                        window.location.reload()
                    } else {
                        confirmAlert({
                            title: 'Error',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                    setLoading(false)
                }).catch(err => {
                    setLoading(false)
                    console.log(err)
                });
            }
        },
    });

    const sendWithdraw = () => {
        if (valueWithdraw > 0) {
            Axios.post(URL_LIST_ALL + 'transactions', { user_id: user.data.id, type: 'ruttien', value: valueWithdraw, status: 0 }, user.config).then(res => {
                if (res.data.status === 'success') {
                    confirmAlert({
                        title: 'ຄວາມ​ສໍາ​ເລັດ',
                        message: 'ຄໍາສັ່ງຖອນເງິນຂອງທ່ານໄດ້ຖືກສົ່ງໄປແລ້ວ. ກະລຸນາລໍຖ້າການອະນຸມັດຂອງຜູ້ເບິ່ງແຍງ',
                        buttons: [{
                            label: 'OK', onClick: () => {
                                window.location.reload()
                            }
                        }]
                    });
                } else {
                    confirmAlert({
                        title: 'Error',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                }
                setValueWithdraw(0)
            }).catch(err => {
                setLoading(false)
                console.log(err)
            });
        }
    }

    return (
        <AkkhorLayout idrole="dashboard" >
            <div className="container-fluid" style={{ backgroundImage: 'url(' + backgroud + ')', backgroundSize: "cover", backgroundPositionX: 'center', backgroundPositionY: 'center',height: "100vh" }}>
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-12 col-sm-2 mb-2">
                        </div>
                        <div className="col-12 col-sm-8">
                            <div className="card height-auto card-question backgroud-transparent mt-5">
                                <div className="card-body">
                                    <h3 className="w-100 text-center mb-3">ຖອນເງິນ</h3>

                                    {userInfo && (!userInfo.fullname || !userInfo.bankname || !userInfo.banknumber || !userInfo.bankaddress) ?
                                        <div className="wrapper p-3 border rounded">
                                            <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                                                <h5 className="text-danger">ທ່ານຈໍາເປັນຕ້ອງປັບປຸງຂໍ້ມູນສ່ວນຕົວຂອງທ່ານ</h5>
                                                <div className="row">
                                                    <div className="col-12 form-group">
                                                        <label>ເລກໂທລະສັບ</label>
                                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("mobile")} autoComplete="off" />
                                                        <AlertErrorForm formik={formik} name="mobile"></AlertErrorForm>
                                                    </div>
                                                    <div className="col-12 form-group">
                                                        <label>ຊື່​ເຕັມ</label>
                                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname")} autoComplete="off" />
                                                        <AlertErrorForm formik={formik} name="fullname"></AlertErrorForm>
                                                    </div>
                                                    <div className="col-12 form-group">
                                                        <label>ຊື່ທະນາຄານ</label>
                                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bankname")} autoComplete="off" />
                                                        <AlertErrorForm formik={formik} name="bankname"></AlertErrorForm>
                                                    </div>
                                                    <div className="col-12 form-group">
                                                        <label>ໝາຍເລກທະນາຄານ</label>
                                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("banknumber")} autoComplete="off" />
                                                        <AlertErrorForm formik={formik} name="banknumber"></AlertErrorForm>
                                                    </div>
                                                    <div className="col-12 form-group">
                                                        <label>ທີ່ຢູ່ທະນາຄານ</label>
                                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bankaddress")} autoComplete="off" />
                                                        <AlertErrorForm formik={formik} name="bankaddress"></AlertErrorForm>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center">
                                                        <button type="submit" disabled={formik.isSubmitting} className="btn col-3 btn-success">{loading ? <Spinner /> : <>ສືບຕໍ່</>}</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        :
                                        <div className="w-100">
                                            {webSeting &&
                                                <>
                                                    <div className="bank-tranfer wrapper border border-warning border-dashed p-3">
                                                        <label>ໃສ່ຈໍານວນທີ່ຈະຖອນ</label>
                                                        <input type="number" className="form-control height32" defaultValue={valueWithdraw} onBlur={(e) => setValueWithdraw(e.target.value)} placeholder="Insert money" />
                                                    </div>
                                                    <div className="w-100 d-flex justify-content-center mt-3">
                                                        <button className="btn btn-success" type="button" onClick={() => sendWithdraw()}>ສົ່ງ</button>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AkkhorLayout>
    )
}