import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import AkkhorLayout from "../layout/akkhor";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import AlertErrorForm from "../component/AlertErrorForm";
import { confirmAlert } from "react-confirm-alert";
import * as Yup from "yup";
import Spinner from "../component/Spinner";
import parse from 'html-react-parser'
import backgroud from "../assets/images/Bg.jpg"

export default function Depositing() {
    const { user } = useSelector((state) => state.auth);
    const [userInfo, setUserInfo] = useState(null)
    const [loading, setLoading] = useState(false);
    const [webSeting, setWebSeting] = useState(false);
    const [moneyDe, setMoneyDe] = useState(0);

    const defaultdetail = {
        username: '', mobile: '', fullname: '', bankname: '', banknumber: '', bankaddress: '', status: 1
    }
    useEffect(() => {
        if (user) {
            Axios.get(URL_LIST_ALL + 'users/' + user.data.id, user.config).then((res) => {
                if (res.data.status === 'success') {
                    setUserInfo(res.data.data)
                } else {
                    console.log(res.data.message);
                }
            }).catch((err) => console.log(err));
            Axios.get(URL_LIST_ALL + 'banks_settings', user.config).then((res) => {
                if (res.data.status === 'success') {
                    setWebSeting(res.data.data)
                } else {
                    console.log(res.data.message);
                }
            }).catch((err) => console.log(err));
        }
    }, [])
    const formik = useFormik({
        initialValues: { ...defaultdetail, ...userInfo },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            mobile: Yup.string().trim().nullable().required('ກະລຸນາໃສ່ໂທລະສັບມືຖືຂອງທ່ານ'),
            fullname: Yup.string().trim().nullable().required('ກະລຸນາໃສ່ຊື່ເຕັມຂອງເຈົ້າ'),
            bankname: Yup.string().trim().nullable().required('ກະລຸນາໃສ່ຊື່ທະນາຄານ'),
            banknumber: Yup.string().trim().nullable().required('ກະລຸນາໃສ່ໝາຍເລກທະນາຄານ'),
            bankaddress: Yup.string().trim().nullable().required('ກະລຸນາໃສ່ທີ່ຢູ່ທະນາຄານ'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (user.data.id > 0) {
                Axios.put(URL_LIST_ALL + 'users/' + user.data.id, values, user.config).then(res => {
                    if (res.data.status === "success") {
                        resetForm();
                        window.location.reload()
                    } else {
                        confirmAlert({
                            title: 'Error',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                    setLoading(false)
                }).catch(err => {
                    setLoading(false)
                    console.log(err)
                });
            }
        },
    });

    const sendDeponsit = () => {
        if (moneyDe) {
            Axios.post(URL_LIST_ALL + 'deposit', { user_id: user.data.id, money: moneyDe }, user.config).then(res => {
                if (res.data.status === 'success') {
                    confirmAlert({
                        title: 'ຄວາມ​ສໍາ​ເລັດ',
                        message: 'ຄໍາສັ່ງເງິນຝາກຂອງທ່ານໄດ້ຖືກສໍາເລັດຢ່າງສໍາເລັດຜົນ. ກະລຸນາລໍຖ້າການອະນຸມັດຂອງຜູ້ເບິ່ງແຍງ',
                        buttons: [{ label: 'OK' }]
                    });
                } else {
                    confirmAlert({
                        title: 'ຜິດພາດ',
                        message: res.data.message,
                        buttons: [{ label: 'OK' }]
                    });
                }
            }).catch(err => {
                setLoading(false)
                console.log(err)
            });
        } else {
            confirmAlert({
                title: 'ຜິດພາດ',
                message: "ກະລຸນາໃສ່ຈໍານວນ",
                buttons: [{ label: 'OK' }]
            });
        }

    }

    return (
        <AkkhorLayout idrole="dashboard">
            <div className="container-fluid" style={{ backgroundImage: 'url(' + backgroud + ')', backgroundSize: "cover", backgroundPositionX: 'center', backgroundPositionY: 'center', height: "100vh" }}>
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-12 col-sm-2 mb-2">
                        </div>
                        <div className="col-12 col-sm-8">
                            <div className="card height-auto card-question mt-5 backgroud-transparent">
                                <div className="card-body">
                                    <h3 className="w-100 text-center mb-3">ເຕີມເງິນ</h3>
                                    {userInfo && (!userInfo.fullname || !userInfo.bankname || !userInfo.banknumber || !userInfo.bankaddress) ?
                                        <div className="wrapper p-3 border rounded">
                                            <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                                                <h5 className="text-danger">ທ່ານຈໍາເປັນຕ້ອງປັບປຸງຂໍ້ມູນສ່ວນຕົວຂອງທ່ານ</h5>
                                                <div className="row">
                                                    <div className="col-12 form-group">
                                                        <label>ເລກໂທລະສັບ</label>
                                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("mobile")} autoComplete="off" />
                                                        <AlertErrorForm formik={formik} name="mobile"></AlertErrorForm>
                                                    </div>
                                                    <div className="col-12 form-group">
                                                        <label>ຊື່​ເຕັມ</label>
                                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname")} autoComplete="off" />
                                                        <AlertErrorForm formik={formik} name="fullname"></AlertErrorForm>
                                                    </div>
                                                    <div className="col-12 form-group">
                                                        <label>ຊື່ທະນາຄານ</label>
                                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bankname")} autoComplete="off" />
                                                        <AlertErrorForm formik={formik} name="bankname"></AlertErrorForm>
                                                    </div>
                                                    <div className="col-12 form-group">
                                                        <label>ໝາຍເລກທະນາຄານ</label>
                                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("banknumber")} autoComplete="off" />
                                                        <AlertErrorForm formik={formik} name="banknumber"></AlertErrorForm>
                                                    </div>
                                                    <div className="col-12 form-group">
                                                        <label>ທີ່ຢູ່ທະນາຄານ</label>
                                                        <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bankaddress")} autoComplete="off" />
                                                        <AlertErrorForm formik={formik} name="bankaddress"></AlertErrorForm>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center">
                                                        <button type="submit" disabled={formik.isSubmitting} className="btn col-3 btn-success">{loading ? <Spinner /> : <>ສືບຕໍ່</>}</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        :
                                        <div className="w-100">
                                            {webSeting &&
                                                <>
                                                    <div className="bank-tranfer wrapper border border-warning border-dashed p-3">
                                                        <h5 className="w-100 text-center mb-3">ຂໍ້​ມູນ​ການ​ໂອນ​ເງິນ​</h5>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 mb-2 pr-0">
                                                                <div className="row mt-2">
                                                                    <div className="col-4 pr-0">
                                                                        <strong>ເຈົ້າຂອງບັນຊີ:</strong>
                                                                    </div>
                                                                    <div className="col-8 pr-0">
                                                                        {webSeting['bank-accout-fullname']}
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-4 pr-0">
                                                                        <strong>ໝາຍເລກທະນາຄານ:</strong>
                                                                    </div>
                                                                    <div className="col-8 pr-0">
                                                                        {webSeting['bank-number']}
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-4 pr-0">
                                                                        <strong>ຊື່ທະນາຄານ:</strong>
                                                                    </div>
                                                                    <div className="col-8 pr-0">
                                                                        {webSeting['bank-name']}
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-4 pr-0">
                                                                        <strong>ທີ່ຢູ່ທະນາຄານ:</strong>
                                                                    </div>
                                                                    <div className="col-8 pr-0">
                                                                        {webSeting['bank-address']}
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-4 pr-0">
                                                                        <strong>ເນື້ອໃນ:</strong>
                                                                    </div>
                                                                    <div className="col-8 pr-0">
                                                                        {parse(webSeting['bank-content'])}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                {webSeting['bank-qrcode'] &&
                                                                    <img src={'https://admin.pokvip.com/' + webSeting['bank-qrcode']} alt="" />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-100 d-flex justify-content-center mt-3">
                                                        <input type="number" className="form-control" placeholder="ໃສ່ຈໍານວນທີ່ຈະຝາກ" defaultValue={moneyDe !== 0 ? moneyDe : ''} onChange={(e) => setMoneyDe(e.target.value)} />
                                                        <button className="btn btn-success" type="button" onClick={() => sendDeponsit()}>ເຕີມເງິນ</button>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AkkhorLayout>
    )
}