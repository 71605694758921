import React, { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { actions } from "../redux/authRedux";
import { resetRedux, saveMyMoney, saveRefreshSocket } from "../redux/Actions";
import onClickOutside from "react-onclickoutside";
import parse from 'html-react-parser'
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import BtnLoginGroup from "../auth/login";
import logo from "../assets/images/logoking.png"
import { Modal } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import wavFile from "../assets/sounds/notification.wav"

function HeaderBar(props) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { my_money } = useSelector((state) => state.save);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false)
  const info = user && user.data ? user.data : null;
  const [socket, setSocket] = useState(null);
  const [listNotification, setListNotification] = useState([]);
  const [soundPlay, setSoundPlay] = useState(false);
  const [countNoti, setCountNoti] = useState(0);
  const [refreshNoti, setRefreshNoti] = useState(false);
  const [modalNotification, setModalNotification] = useState(false);

  // const [notifi, setNotifi] = useState([])
  useEffect(() => {
    if (user) {
      Axios.get(URL_LIST_ALL + "users_money?limit=1&user_id=" + user.data.id, user.config).then((res) => {
        if (res.data.status === "success") {
          if (res.data.data && res.data.data[0]) {
            dispatch(saveMyMoney(res.data.data[0].money));
          }
        } else {
          if (res.data.message === "Vui lòng đăng nhập") {
            dispatch(resetRedux());
            dispatch(actions.login(null));
          }
        }
      }).catch((err) => console.log(err));
    }
  }, [user])

  useEffect(() => {
    if (soundPlay) {
      const audio = new Audio(wavFile);
      audio.play()
      setSoundPlay(false)
    }
  }, [soundPlay]);

  useEffect(() => {
    if (user) {
      Axios.get(URL_LIST_ALL + "notifications?send_to=" + user.data.id, user.config).then(async (res) => {
        if (res.data.status === 'success') {
          let datars = res.data.data
          setListNotification(datars)
          let count_no_seen = 0
          datars.forEach((item) => {
            if (item.seen === 0) {
              count_no_seen += 1;
            }
          });
          setCountNoti(count_no_seen)

        } else {
          alert(res.data.message)
        }
      }).catch((err) => { console.log(err); });
    }
  }, [user, refreshNoti])

  //Hàm xử lý đăng xuất
  const dologout = () => {
    confirmAlert({
      title: "ອອກ​ຈາກ​ລະ​ບົບ",
      message: "ທ່ານຕ້ອງການອອກຈາກລະບົບບໍ ?",
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: "ໂກ",
          onClick: () => {
            dispatch(resetRedux());
            dispatch(actions.login(null));
          },
        },
        { label: "ໂຂງ" },
      ],
    });
  };
  HeaderBar["handleClickOutside_header"] = () => setShow(false);

  useEffect(() => {
    const connectWebSocket = () => {
      // setFlag(true)
      const socketInstance = new WebSocket('wss://socket.pokvip.com');
      socketInstance.onopen = () => { };
      socketInstance.onmessage = (evt) => {
        if (evt.data) {
          let data = evt.data
          try {
            data = jwtDecode(data)
            if (data) {
              if (data.channel && data.channel == 'admin_notification') {
                if (data.data.send_to === user.data.id) {
                  setRefreshNoti(data.data)
                  dispatch(saveRefreshSocket(data));
                  setSoundPlay(true)
                }
              }
            }
          } catch (error) {
          }
        }
      };
      socketInstance.onclose = (event) => {
        setTimeout(() => {
          connectWebSocket();
        }, 1000);
      };
      setSocket(socketInstance);
    };
    if (!socket) {
      connectWebSocket();
    }
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [socket]);

  const markSeen = (id, href = null) => {
    Axios.put(URL_LIST_ALL + "notifications/" + id, { seen: 1 }, user.config).then(async (res) => {
      if (href) {
        window.location.href = href
      } else {
        setRefreshNoti(!refreshNoti)
      }
    })
  }
  const markSeenAll = (id, href = null) => {
    Axios.post(URL_LIST_ALL + "seenall", {}, user.config).then(async (res) => {
      if (href) {
        window.location.href = href
      } else {
        setRefreshNoti(!refreshNoti)
      }
    })
  }

  return (
    <>
      {/* Header Menu Area Start Here */}
      <div className="navbar navbar-expand-md header-menu-one justify-content-center">
        <div className="sidebar-left ">
          <div className="group-button-sidebar d-block d-sm-none">
            <button type="button" className="btn btn-sidebar" onClick={() => setModalShow(true)}>
              <span className="fas fa-bars"></span>
            </button>
          </div>
          {info ? <>
            <Link to="/depositing" className="d-none d-sm-block">
              <i className="fas fa-money-check-alt"></i>
              <span>ເຕີ່ມເງີນ</span>
            </Link>
            <Link to="/withdraw" className="d-none d-sm-block">
              <i className="fas fa-dollar-sign"></i>
              <span>ຖອນເງີນ</span>
            </Link>
            <Link to="/history" className="d-none d-sm-block">
              <i className="fas fa-user-clock"></i>
              <span>ປະຫວັດສາດ</span>
            </Link>
          </>
            :
            <div className="w-100 d-none d-sm-flex justify-content-end align-items-center">
              <BtnLoginGroup />
            </div>
          }
        </div>
        <div className="sidebar-center">
          <div className="header-logo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
        </div>
        <div className="sidebar-right">
          {info ? <>
            <Link to="/my-profile" className="d-none d-sm-block">
              <i className="far fa-user-circle"></i>
              <span>ຂໍ້​ມູນ​ສ່ວນ​ຕົວ</span>
            </Link>
            <Link to="/my-profile" className="profile">
              <span>{info.username}</span>
              <span>{my_money && "$" + my_money}</span>
            </Link>
            <a href="#" type="button" className="d-none d-sm-block" onClick={() => setModalNotification(true)}>
              <i className="far fa-bell"></i>
              <span>ແຈ້ງການ</span>
              {countNoti > 0 && <small className="count-number">{countNoti}</small>}
            </a>
            <a href="#logout" onClick={dologout} className="d-none d-sm-block">
              <i className="fas fa-power-off"></i>
              <span>ອອກ​ຈາກ​ລະ​ບົບ</span>
            </a>

          </>
            :
            <div className="d-block d-sm-none">
              <BtnLoginGroup />
            </div>
          }
        </div>
        <Modal show={modalShow} onHide={() => setModalShow(false)} animation={true} size="md" className="modal-login">
          <Modal.Body className="login-group">
            <button type="button" className="btn btn-modal-close" onClick={() => setModalShow(false)}><span className="fas fa-times"></span></button>
            <div className="w-100 sidebar-menu">
              <a className="w-100 btn" href="/depositing">ເຕີມເງິນ</a>
              <a className="w-100 btn mt-1" href="/withdraw">ຖອນເງິນ</a>
              <a className="w-100 btn mt-1" href="/history">ປະຫວັດສາດ</a>
              {info ? <a className="w-100 btn mt-1" href="#" onClick={() => { dologout(); setModalShow(false) }}> ອອກ​ຈາກ​ລະ​ບົບ</a> : ''}
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {info ? <>
        <div className="d-sm-none header-mobile">
          <Link to="/" className="menu-item">
            <i className="fas fa-house-user"></i>
            <span>ຫນ້າທໍາອິດ</span>
          </Link>
          {/* Nạp tiền */}
          <Link to="/depositing" className="menu-item">
            <i className="fas fa-money-check-alt"></i>
            <span>ເຕີ່ມເງີນ</span>
          </Link>
          {/* Rút tiền */}
          <Link to="/withdraw" className="menu-item">
            <i className="fas fa-dollar-sign"></i>
            <span>ຖອນເງີນ</span>
          </Link>
          {/* Thông báo */}
          <div type="button" className="menu-item" onClick={() => setModalNotification(true)}>
            <i className="far fa-bell"></i>
            <span>ແຈ້ງການ</span>
            {countNoti > 0 && <small className="count-number">{countNoti}</small>}
          </div>
          <Link to="/my-profile" className="menu-item">
            <i className="far fa-user-circle"></i>
            <span>ໂປຣໄຟລ໌</span>
          </Link>
        </div>
        <Modal show={modalNotification} onHide={() => setModalNotification(false)} animation={true} size="md" className="modal-login">
          <Modal.Body className="notification-group p-0">
            <button type="button" className="btn btn-modal-close" onClick={() => setModalNotification(false)}><span className="fas fa-times"></span></button>
            <div className="w-100 d-flex justify-content-center border-bottom">
              <button className="btn readall" type="button" onClick={() => markSeenAll()}>ອ່ານທັງຫມົດ</button>
            </div>
            {listNotification && listNotification.length > 0 ? listNotification.map((item, i) => {
              return (<div className={"notification-item p-2 " + (item.seen === 1 ? "seen" : '')} key={i}>
                <div className="content pl-2" onClick={() => { markSeen(item.id, item.actions) }}>
                  {parse(item.title)}
                </div>
                {item.seen !== 1 &&
                  <div className="btn-seen">
                    <button className="btn p-0" type="button" title="ໝາຍວ່າເບິ່ງແລ້ວ" onClick={() => { markSeen(item.id) }}>
                      <i className="far fa-dot-circle"></i>
                    </button>
                  </div>
                }

              </div>)
            })
              :
              <p className="p-2">ບໍ່ມີການປະກາດ</p>
            }
          </Modal.Body>
        </Modal>
      </>
        : <></>
      }

    </>
  );
}
export default onClickOutside(HeaderBar, {
  handleClickOutside: () => HeaderBar["handleClickOutside_header"],
});
