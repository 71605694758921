import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { actions } from "../redux/authRedux";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import AlertErrorForm from "../component/AlertErrorForm";
import { saveMyMoney, SAVE_MONEY } from "../redux/Actions";
import { confirmAlert } from "react-confirm-alert";

export default function BtnLoginGroup(props) {
  const [modalShow, setModalShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('login')
  const dispatch = useDispatch();

  const createConfig = (_token) => {
    return {
      headers: {
        'Authorization': 'Bearer ' + _token,
        'Content-Type': 'application/json; charset=UTF-8',
        'search': ''
      }
    }
  }

  const validLogin = {
    username: Yup.string().trim().nullable().required('ຊື່ຜູ້ໃຊ້ບໍ່ສາມາດຫວ່າງເປົ່າໄດ້'),
    password: Yup.string().trim().nullable().required('ລະຫັດຜ່ານບໍ່ສາມາດຫວ່າງເປົ່າໄດ້'),
  }
  const validsignup = {
    username: Yup.string().trim().nullable().required('ຊື່ຜູ້ໃຊ້ບໍ່ສາມາດຫວ່າງເປົ່າໄດ້'),
    mobile: Yup.string().trim().nullable().required('ເບີໂທລະສັບບໍ່ສາມາດຫວ່າງເປົ່າໄດ້'),
    password: Yup.string().trim().nullable().required('ລະຫັດຜ່ານບໍ່ສາມາດຫວ່າງເປົ່າໄດ້'),
    repassword: Yup.string().required('Password must be confirmed').oneOf([Yup.ref('password'), null], 'ລະຫັດຜ່ານແລະລະຫັດຜ່ານການຢືນຢັນຂອງທ່ານບໍ່ກົງກັນ.'),
  }

  const formikLogin = useFormik({
    initialValues: { username: '', password: '', repassword: '' },
    enableReinitialize: true,
    validationSchema: Yup.object().shape(type === 'login' ? validLogin : validsignup),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoading(true)
      if (type === 'login') {
        Axios.post(URL_LIST_ALL + "login", values).then((res) => {
          setSubmitting(false);
          if (res.data.status === "success") {
            let user = res.data;
            console.log(user);
            user.config = createConfig(res.data.access_token);
            dispatch(actions.login(user));
            dispatch(saveMyMoney(res.data.money));
            dispatch(SAVE_MONEY(res.data.money));
          } else alert(res.data.message)
          setLoading(false)
        }).catch((err) => {
          console.log(err);
          setLoading(false)
        });
      } else {
        Axios.post(URL_LIST_ALL + "registry", values).then((res) => {
          setSubmitting(false);
          setModalShow(false)
          if (res.data.status === "success") {//regist success
            confirmAlert({
              title: 'ຄວາມ​ສໍາ​ເລັດ',
              message: 'ສຳເລັດການລົງທະບຽນເຕັມ. ເຈົ້າຕ້ອງການເຂົ້າສູ່ລະບົບດຽວນີ້ບໍ?',
              buttons: [{
                label: 'OK', onClick: () => {
                  let user = res.data;
                  user.config = createConfig(res.data.access_token);
                  dispatch(actions.login(user));
                  dispatch(saveMyMoney(res.data.money));
                  dispatch(SAVE_MONEY(res.data.money));
                }
              }, { label: 'Cancle' }]
            });

          } else alert(res.data.message)
          setLoading(false)
        }).catch((err) => {
          console.log(err);
          setLoading(false)
        });
      }
    },
  });

  return (
    <div className="outer-login-group d-flex">
      {/* Đăng nhập */}
      <button className="btn btn-sm btn-login" type="button" onClick={() => { setModalShow(true); setType('login') }}>
        ເຂົ້າສູ່ລະບົບ
      </button>
      {/* Đăng ký */}
      <button className="btn btn-sm btn-login ml-2 d-none d-sm-block" type="button" onClick={() => { setModalShow(true); setType('signup') }}>
        ລົງທະບຽນ
      </button>
      <Modal show={modalShow} onHide={() => setModalShow(false)} animation={true} size="md" className="modal-login">
        <Modal.Body className="login-group">
        <button type="button" className="btn btn-modal-close" onClick={() => setModalShow(false)}><span className="fas fa-times"></span></button>
          <div className="forms">
            {/* <button className="btn btn-close" type="button" onClick={() => setModalShow(false)}><i className="far fa-times-circle"></i></button> */}
            <div className="w-100 form login">
              <div className="w-100 d-flex justify-content-center btn-group ">
                <button type="button" className={"col-6 btn btn-action btn-action-login " + (type === 'login' ? 'active' : '')} onClick={() => setType('login')}>ເຂົ້າສູ່ລະບົບ</button>
                <button type="button" className={"col-6 btn btn-action btn-action-login " + (type !== 'login' ? 'active' : '')} onClick={() => setType('signup')}>ລົງທະບຽນ</button>
              </div>
              <form method="post" onSubmit={formikLogin.handleSubmit}>
                <div className="input-field">
                  <input type="text" placeholder="ຊື່ຜູ້ໃຊ້" {...formikLogin.getFieldProps("username")} />
                  <i className="uil uil-envelope icon"></i>
                </div>
                <AlertErrorForm formik={formikLogin} name="username"></AlertErrorForm>
                {type === 'signup' &&
                  <>
                    <div className="input-field">
                      <input type="text" placeholder="ເລກໂທລະສັບ" {...formikLogin.getFieldProps("mobile")} />
                      <i className="uil uil-envelope icon"></i>
                    </div>
                    <AlertErrorForm formik={formikLogin} name="mobile"></AlertErrorForm>
                  </>

                }
                <div className="input-field">
                  <input type="password" className="password" placeholder="ລະຫັດຜ່ານ" {...formikLogin.getFieldProps("password")} />
                  <i className="uil uil-lock icon"></i>
                  <i className="uil uil-eye-slash showHidePw"></i>
                </div>
                <AlertErrorForm formik={formikLogin} name="password"></AlertErrorForm>
                {type === 'signup' &&
                  <>
                    <div className="input-field">
                      <input type="password" className="repassword" placeholder="ປ່ອນລະຫັດຜ່ານ" {...formikLogin.getFieldProps("repassword")} />
                      <i className="uil uil-lock icon"></i>
                      <i className="uil uil-eye-slash showHidePw"></i>
                    </div>
                    <AlertErrorForm formik={formikLogin} name="repassword"></AlertErrorForm>
                  </>
                }
                {type === 'login' &&
                  <div className="checkbox-text">
                    <a href="#" className="text">ດືງລະຫັດຄືນ</a>
                  </div>
                }
                <div className="input-field button">
                  <button type="submit" disabled={formikLogin.isSubmitting} className="btn w-100">
                    {loading ? <div className="w-100 d-flex justify-content-center"><div className="loader"></div></div>
                      : <>
                        {type === 'login' ? "ເຂົ້າສູ່ລະບົບ" : "ລົງທະບຽນ"}
                      </>}
                  </button>
                </div>
              </form>
              {type === 'login' ?
                <div className="login-signup">
                  <span className="text">ບໍ່ແມ່ນສະມາຊິກບໍ?
                    <a href="#signup" className="text signup-link" onClick={() => setType('signup')}>ລົງທະບຽນ</a>
                  </span>
                </div>
                :
                <div className="login-signup">
                  <span className="text">ເຈົ້າໄດ້ລົງທະບຽນບັນຊີລະບໍ?
                    <a href="#login" className="text signup-link" onClick={() => setType('login')}>ເຂົ້າສູ່ລະບົບ</a>
                  </span>
                </div>
              }

            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
