import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Provider } from "react-redux";
import storeblackjack from "./redux/storeblackjack";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from "./serviceWorker";
import mockAxios from "./mocks";
import { ChakraProvider } from "@chakra-ui/react";
import blackjackTheme from "./styles/theme";
import store, { persistor } from "./redux/store";

import "@fontsource/hind-siliguri";
import "@fontsource/lobster";
import "@fontsource/fugaz-one";

import './assets/css/confermalert.css'
import './assets/css/bootstrap.min.css'
import './assets/css/animate.min.css'
import './assets/css/style.css'
import './assets/css/all.min.css'
import './assets/fonts/flaticon.css'

// require("dotenv").config();
var axios = require("axios");
const { REACT_APP_USE_MOCK_DATA } = process.env;
if (REACT_APP_USE_MOCK_DATA === "true") mockAxios(axios);
const rootElement = document.getElementById('root');

const root = createRoot(rootElement);
if (window.location.pathname === '/xi-dach') {
  root.render(
    <ChakraProvider theme={blackjackTheme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ChakraProvider>
  );
} else {
  root.render(
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
        <App />
      </PersistGate>
    </Provider>
  )
}

serviceWorker.unregister();
