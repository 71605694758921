import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";

import './App.css';
import HomePage from "./home/HomePage";
import XiDachGame from "./xidach/XiDachGame";
import BauCuaGame from "./baucua/BauCuaGame";
import XengGame from "./xeng/XengGame";
import XiNgauGame from "./xingau/XiNgauGame";
import Profile from "./auth/profile";
import Depositing from "./auth/Depositing";
import History from "./auth/History";
import WithDraw from "./auth/WithDraw";

function App(props) {
  const { user } = useSelector((state) => state.auth);
  const isAuthorized = user != null;
  const Checker = () => {
    return <Redirect to="/" />
  }
  return (
    <BrowserRouter basename={""}>
      <Suspense >
        <Switch>
          <Route exact path="/"><HomePage /></Route>
          <Route path="/xi-dach"><XiDachGame /></Route>
          <Route path="/bau-cua"><BauCuaGame /></Route>
          <Route path="/xeng"><XengGame /></Route>
          <Route path="/xingau"><XiNgauGame /></Route>
          {isAuthorized ? <>
            <Route path="/my-profile"><Profile /></Route>
            <Route path="/depositing"><Depositing /></Route>
            <Route path="/history"><History /></Route>
            <Route path="/withdraw"><WithDraw /></Route>
          </>
            :
            <Checker />
          }

        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
