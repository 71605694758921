import { useState, useEffect } from "react";
import backgroud from "../assets/images/baucua/bg-baucua.png"
import dia from "../assets/images/baucua/dia.png"
import bat from "../assets/images/baucua/bat.png"
import bau from "../assets/images/baucua/bau.png"
import cua from "../assets/images/baucua/cua.png"
import ca from "../assets/images/baucua/ca.png"
import huu from "../assets/images/baucua/huu.png"
import tom from "../assets/images/baucua/tom.png"
import ga from "../assets/images/baucua/ga.png"
import chip10 from "../assets/images/baucua/chip10.png"
import chip20 from "../assets/images/baucua/chip20.png"
import chip50 from "../assets/images/baucua/chip50.png"
import chip100 from "../assets/images/baucua/chip100.png"
import chip200 from "../assets/images/baucua/chip200.png"
import chip500 from "../assets/images/baucua/chip500.png"
import chipx from "../assets/images/baucua/casino-chip.png"

import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import AkkhorLayout from "../layout/akkhor";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { saveGameResult, saveGameResultCache, saveMyBet, saveMyMoney } from "../redux/Actions";
import { jwtDecode } from "jwt-decode";
import parse from 'html-react-parser'

export default function BauCuaGame() {
    const { user } = useSelector((state) => state.auth);
    const { my_money } = useSelector((state) => state.save);
    const { mybet } = useSelector((state) => state.save);
    const { gameresult } = useSelector((state) => state.save);
    const { gameresultcache } = useSelector((state) => state.save);
    const dispatch = useDispatch();
    const list_item = [bau, cua, ca, huu, tom, ga]
    const list_item_str = ['bau', 'cua', 'ca', 'huu', 'tom', 'ga']
    const list_item_money = [10, 50, 100, 500, 1000, "All in"]
    const list_chip_money = [chip10, chip50, chip100, chip500, chip200, chip20]
    const [gameStart, setGameStart] = useState(false)
    const [totalMoneyBet, setTotalMoneyBet] = useState(0)
    const [betMoney, setBetMoney] = useState(0)
    const [datCuocStt, setDatCuocStt] = useState(true)
    const [textCount, setTextCount] = useState('')
    const [timeLeft, setTimeLeft] = useState(null);
    const [playAgain, setPlayAgain] = useState(false);
    const [statusMoney, setStatusMoney] = useState(null);
    const [onClose, setOnClose] = useState(false);
    const [socket, setSocket] = useState(null);
    const [moBat, setMoBat] = useState(false)
    const [loadKq, setLoadKq] = useState(false)
    const [notification, setNotification] = useState([])
    const [refreshNoti, setRefreshNoti] = useState(null)
    const [flag, setFlags] = useState(true)

    useEffect(() => {
        const interval = setInterval(() => {
            if (new Date().getSeconds() <= 40) {
                setTextCount('ປິດຫຼັງຈາກ:') //close after
                setTimeLeft(40 - new Date().getSeconds());
                setDatCuocStt(true)
                setOnClose(false)
                setMoBat(true)
                if (new Date().getSeconds() > 20) {
                    setNotification([])
                }
            } else {
                setDatCuocStt(false)
                if (new Date().getSeconds() <= 50) {
                    setMoBat(false)
                    setOnClose(true)
                    setTextCount('ເປີດຫຼັງຈາກ:') //open after
                    setTimeLeft(50 - new Date().getSeconds());
                } else {
                    if (new Date().getSeconds() >= 50 && new Date().getSeconds() < 55) {
                        dispatch(saveGameResult(gameresultcache.game_result))
                        setGameStart(true)
                    }
                    if (new Date().getSeconds() > 55) {
                        dispatch(saveMyBet({ 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }))
                        setTextCount('ເກມໃໝ່ຫຼັງຈາກ:')//new game after
                        setTimeLeft(60 - new Date().getSeconds());
                    }
                    if (new Date().getSeconds() >= 57) {
                        setFlags(true)
                        setLoadKq(!loadKq)
                        setStatusMoney(null)
                        setBetMoney(0)
                        setPlayAgain(false);
                    }
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [gameresultcache])


    useEffect(() => {
        if (flag) {
            if (gameresult.length == 0) {
                loadGameResult()
                setLoadKq(true)
                setFlags(false)
            } else {
                setTimeout(() => {
                    loadGameResult()
                }, 5000);
            }
        }
    }, [loadKq, flag])

    const loadGameResult = () =>{
        Axios.get(URL_LIST_ALL + "game_result?game_type=baucua").then((res) => {
            if (res.data.status === 'success') {
                if (res.data.data) {
                    let data = res.data.data
                    data = jwtDecode(data)
                    dispatch(saveGameResultCache(data))
                }
            }
        })
    }

    useEffect(() => {
        if (onClose && totalMoneyBet > 0 && user) {
            let data_insert = { user_id: user.data.id, user_bet: JSON.stringify(mybet), total_money: totalMoneyBet, user_money: my_money, game_name: 'baucua', game_id: gameresultcache.id };
            Axios.post(URL_LIST_ALL + "users_bet", data_insert, user.config).then((res) => {
            }).catch((err) => { });
        }
    }, [onClose])

    useEffect(() => {
        const connectWebSocket = () => {
            const socketInstance = new WebSocket('wss://socket.pokvip.com');
            socketInstance.onopen = () => { };
            socketInstance.onmessage = (evt) => {
                if (evt.data) {
                    let data = evt.data
                    try {
                        data = jwtDecode(data)
                        if (data) {
                            if (data.type && data.type == 'notification_play' && data.gamename == 'baucua') {
                                setRefreshNoti(data)
                            }
                            if (data.type && data.type == 'gameresult' && data.gamename == 'baucua') {
                                loadGameResult()
                            }
                        }
                    } catch (error) { }
                }
            };
            socketInstance.onclose = (event) => {
                setTimeout(() => {
                    connectWebSocket();
                }, 1000);
            };

            setSocket(socketInstance);
        };
        if (!socket) {
            connectWebSocket();
        }
        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [socket]);

    useEffect(() => {
        if (refreshNoti) {
            let data_notification = refreshNoti
            setNotification(data_notification.title)
        }
    }, [refreshNoti])


    useEffect(() => {
        if (timeLeft === 0) {
            setTimeLeft(null)
        }
        if (!timeLeft) return;
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    const onBetAction = (choice) => {
        if (!playAgain) {
            let clone = { ...mybet }
            let newBetMoney = betMoney === 'All in' ? my_money : betMoney
            clone[choice] = choice in clone ? clone[choice] + (newBetMoney) : newBetMoney
            let total_bet_choice = clone[choice]
            let total_bet = 0
            let newMoney = my_money - betMoney
            if (newBetMoney <= my_money) {
                Object.keys(clone).forEach(function (key) {
                    if (clone[key] > 0) {
                        total_bet = total_bet + betMoney
                        dispatch(saveMyMoney(newMoney && betMoney !== 'All in' ? newMoney : 0));
                    }
                })
                setTotalMoneyBet(total_bet)
                if (user) {
                    dispatch(saveMyBet(clone))
                    let dataupdate = { money: newMoney && betMoney !== 'All in' ? newMoney : 0, game_id: gameresultcache.id }
                    let data_insert = { user_id: user.data.id, choice: choice,user_choice:clone, money: total_bet_choice, game_id: gameresultcache.id,update_money:dataupdate }
                    if (total_bet > 0) {
                        Axios.post(URL_LIST_ALL + "users_bet_item", data_insert, user.config).then((res) => { }).catch((err) => { });
                    }
                } else {
                    confirmAlert({
                        title: 'ຄຳເຕືອນ',
                        message: 'ກະ​ລຸ​ນາ​ເຂົ້າ​ສູ່​ລະ​ບົບ !',
                        buttons: [{ label: 'OK' }]
                    });
                }
            } else {
                confirmAlert({
                    title: 'ຄຳເຕືອນ',
                    message: 'ເງິນ​ບໍ່​ພຽງ​ພໍ​!',
                    buttons: [{ label: 'OK' }]
                });
            }
        }
    }
    useEffect(() => {
        if (gameStart) {
            let rs_ketqua = gameresult
            setTimeout(() => {
                setGameStart(false)
                setMoBat(true)
                if (rs_ketqua) {
                    let newMoney = my_money && betMoney !== 'All in' ? my_money : 0
                    let totalbet = mybet[0] + mybet[1] + mybet[2] + mybet[3] + mybet[4] + mybet[5]
                    rs_ketqua.forEach((item) => {
                        if (mybet && mybet[item]) {
                            newMoney = newMoney + (mybet[item] * 2)
                        }
                    });
                    if (newMoney !== my_money) {
                        dispatch(saveMyMoney(newMoney));
                        let after_money = newMoney - my_money;
                        setStatusMoney(<span className="text-success">+{newMoney - my_money}</span>)
                        let dataupdate = { money: newMoney, after_money: after_money, game_id: gameresultcache.id }
                        Axios.post(URL_LIST_ALL + "update_money", dataupdate, user.config).then((res) => {
                        }).catch((err) => {
                            console.log(err);
                        });
                    } else {
                        console.log(totalbet);
                        if (totalbet > 0) {
                            setStatusMoney(<span className="text-danger">{"-" + totalbet}</span>)
                        }
                    }
                    setPlayAgain(true)
                }
            }, 4000);
        }
    }, [gameStart])

    return (
        <AkkhorLayout idrole="dashboard">
            <div className="container-fluid bg-baucua baucua" style={{ backgroundImage: 'url(' + backgroud + ')' }}>
                <div className="w-100 d-flex justify-content-center align-items-center overflow-hidden pb-2">
                    <div className={'cai-dia d-flex justify-content-center ' + (gameStart && 'laclac')} style={{ backgroundImage: 'url(' + dia + ')' }}>
                        <div className="baucua-group d-flex justify-content-center align-items-center">
                            {gameresult && gameresult.length > 0 ? gameresult.map((item, i) => {
                                return (<div className={"item " + list_item_str[item]} key={i}><img src={list_item[item]} alt="" /></div>)
                            })
                                :
                                <>
                                    <div className="item bau"><img src={bau} alt="" /></div>
                                    <div className="item cua"><img src={cua} alt="" /></div>
                                    <div className="item ca"><img src={ca} alt="" /></div>
                                </>
                            }
                        </div>
                        <div className={"caibat " + (!moBat ? "open" : '')} style={{ backgroundImage: 'url(' + bat + ')' }}>Open</div>
                    </div>
                    {!gameStart &&
                        <div className="w-100 d-flex justify-content-center count-down-group">
                            <div className="count-down text-center">
                                <p>{textCount}</p>
                                <div className="w-100 d-flex justify-content-center"><span>{(timeLeft ? timeLeft : 0) + 's'}</span></div>
                            </div>
                        </div>
                    }

                </div>
                {/* {console.log('stt_mb',moBat)} */}
                {statusMoney &&
                    <div className="status-money" style={{ backgroundImage: "url(" + chipx + ")" }}>
                        <span>{statusMoney}</span>
                    </div>
                }
                <div className="row d-flex justify-content-center mt-3">
                    <div className="col-12 col-md-9">
                        <div className="row">
                            {list_item.map((item, i) =>
                                <div className="col-4 col-md-2 item-select" key={i}>
                                    <button className="btn btn-sm" type="button" onClick={() => onBetAction(i)} disabled={datCuocStt ? false : true}>
                                        {mybet && mybet[i] && mybet[i] > 0 ? <span className="show_number">{mybet[i]}</span> : ''}
                                        <img src={item} alt="" />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 listmoney">
                        <div className="row">
                            {list_item_money.map((item, i) => {
                                return (
                                    <div className="col-2 item-chip" key={i}>
                                        <button type="button" className={'btn chipmoney ' + (betMoney === item ? 'choice' : '')} onClick={() => setBetMoney(item)} disabled={datCuocStt ? false : true}>
                                            <img src={list_chip_money[i]} alt="" />
                                            <span className="text">{item}</span>
                                        </button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {notification && notification.length > 0 &&
                        <div className="container-fluid notification-play">
                            <div className="row">
                                {notification.map((item, i) =>
                                    <div className="col-4 col-sm-2 item" key={i}>
                                        <div className="noti-item" key={i}>
                                            {parse(item)}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </AkkhorLayout>
    )
}