import { configureStore } from "@reduxjs/toolkit";
import blackjackReducer from "./blackjackSlice";
import { rootReducer } from './rootReducer';

const store = configureStore({
  reducer: {
    blackjack: blackjackReducer,
    auth: rootReducer
  },
  devTools: true,
});

export default store;
