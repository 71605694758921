import { initialState, slice } from "./Slice";

const { actions } = slice;

export const saveAnswers = (Answers) => dispatch =>
{
    dispatch(actions.choice({Answers}))
}

export const saveChangeTimeTable = (TimeTableSave) => dispatch =>
{
    dispatch(actions.choice({TimeTableSave}))
}

export const saveUser = (user) => dispatch =>
{
    dispatch(actions.choice({user}))
}
export const saveRefreshSocket = (datasocket) => dispatch =>
{
    dispatch(actions.choice({datasocket}))
}
export const saveMyMoney = (my_money) => dispatch =>
{
    dispatch(actions.choice({my_money}))
}
export const saveMyBet = (mybet) => dispatch =>
{
    dispatch(actions.choice({mybet}))
}
export const saveGameResult = (gameresult) => dispatch =>
{
    dispatch(actions.choice({gameresult}))
}
export const saveGameResultCache = (gameresultcache) => dispatch =>
{
    dispatch(actions.choice({gameresultcache}))
}

export const saveListYear = (listyear) => dispatch =>
{
    dispatch(actions.choice({listyear}))
}

export const saveRedux = (state) => dispatch =>
{ 
    dispatch(actions.choice(state))
}
export const resetRedux = () => dispatch =>
{
    dispatch(actions.choice(initialState))
}

export const UPDATE_SCORE = (state, action) => dispatch =>
{
    dispatch(actions.UPDATE_SCORE(state, action))
}
export const SAVE_MONEY = (state, action) => dispatch =>
{
    dispatch(actions.SAVE_MONEY(state, action))
}
export const DETERMINE_WINNER = (state) => dispatch =>
{
    dispatch(actions.DETERMINE_WINNER(state))
}
export const DEALER_TURN = (state, action) => dispatch =>
{
    dispatch(actions.DEALER_TURN(state, action))
}
export const DEAL_A_CARD_PLAYER = (state) => dispatch =>
{
    dispatch(actions.DEAL_A_CARD_PLAYER(state))
}
export const DEAL_A_CARD_DEALER = (state) => dispatch =>
{
    dispatch(actions.DEAL_A_CARD_DEALER(state))
}
export const DEALER_SHUFFLE = (state) => dispatch =>
{
    dispatch(actions.DEALER_SHUFFLE(state))
}
export const DEALER_HIT = (state) => dispatch =>
{
    dispatch(actions.DEALER_HIT(state))
}

export const SET_DEALER_STANDING = (state, action) => dispatch =>
{
    dispatch(actions.SET_DEALER_STANDING(state, action))
}
export const SET_BET = (state, action) => dispatch =>
{
    dispatch(actions.SET_BET(state, action))
}
export const PLAYER_HIT = (state) => dispatch =>
{
    dispatch(actions.PLAYER_HIT(state))
}
export const DOUBLE_DOWN = (state) => dispatch =>
{
    dispatch(actions.DOUBLE_DOWN(state))
}
export const SET_PLAYER_STANDING = (state, action) => dispatch =>
{
    dispatch(actions.SET_PLAYER_STANDING(state, action))
}
export const SET_WALLET = (state, action) => dispatch =>
{
    dispatch(actions.SET_WALLET(state, action))
}
export const SET_COMPLETED_QUESTS = (state, action) => dispatch =>
{
    dispatch(actions.SET_COMPLETED_QUESTS(state, action))
}
export const START_GAME = (state) => dispatch =>
{
    dispatch(actions.START_GAME(state))
}
export const GAME_TYPE = (state, action) => dispatch =>
{
    dispatch(actions.GAME_TYPE(state, action))
}
