import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AkkhorLayout from "../layout/akkhor";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import backgroud from "../assets/images/Bg.jpg"
import Moment from "react-moment";
import Pagination from "react-js-pagination";
import Loading from "../component/Loading";

export default function History() {
    const { user } = useSelector((state) => state.auth);
    const [detail, setDetail] = useState(null)
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const limit = 10;
    const [totalRecord, setTotalRecord] = useState(10);
    const now = parseInt(new Date().getTime() / 1000) + 1 * 60 * 60;

    useEffect(() => {
        if (user) {
            setLoading(true)
            Axios.get(URL_LIST_ALL + 'transactions?user_id=' + user.data.id + "&page=" + page, user.config).then((res) => {
                if (res.data.status === 'success') {
                    setDetail(res.data.data)
                    setTotalRecord(res.data.total);
                } else {
                    console.log(res.data.message);
                }
                setLoading(false)
            }).catch((err) => {
                console.log(err)
                setLoading(false)
            });
        }
    }, [page])


    return (
        <AkkhorLayout idrole="dashboard">
            <div className="container-fluid" style={{ backgroundImage: 'url(' + backgroud + ')', backgroundSize: "cover", backgroundPositionX: 'center', backgroundPositionY: 'center', height: "100vh" }}>
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-12 col-sm-2 mb-2">
                        </div>
                        <div className="col-12 col-sm-8">
                            <div className="card height-auto card-question  mt-5">
                                <div className="card-body">
                                    <h3 className="w-100 text-center mb-3">ປະຫວັດສາດ</h3>
                                    {loading ? <Loading color="secondary" expire={user.expires <= now} /> : <>
                                        <div className="w-100 list-history d-none d-sm-block">
                                            <div className="col-12 border-bottom pt-1 pb-1">
                                                <div className="row" >
                                                    <div className="col-3">
                                                        ເວລາ
                                                    </div>
                                                    <div className="col-3">
                                                        ຊື່ການຄ້າ
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        ຈໍານວນເງິນ
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        ສະຖານະ
                                                    </div>
                                                </div>
                                            </div>
                                            {detail && detail.map((item, i) => {
                                                return <div className="col-12 pt-1 pb-1 border-bottom" key={i}>
                                                    <div className="row" >
                                                        <div className="col-3">
                                                            <Moment format="DD/MM/YYYY">{new Date(item.datecreate)}</Moment>
                                                        </div>
                                                        <div className="col-3">
                                                            {item.type == 'naptien' && 'ທີ່ນີ້'}
                                                            {item.type == 'thangcuoc' && 'ຊະນະເກມ'}
                                                            {item.type == 'datcuoc' && 'ວາງເດີມພັນກັບເກມ'}
                                                            {item.type == 'ruttien' && 'ເຕີມເງິນ'}
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            {item.value}
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            {item.status == 1 && 'ຄວາມ​ສໍາ​ເລັດ'}
                                                            {item.status == 2 && 'ກຳລັງກວດສອບ'}
                                                            {item.status == 3 && 'ຍົກເລີກ'}
                                                            {item.status == 0 && 'ລໍຖ້າຢູ່'}
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                        <div className="w-100 list-history d-block d-sm-none">
                                            {detail && detail.map((item, i) => {
                                                return <div className="col-12 pt-1 pb-1 border mt-3 rounded shadow-sm" key={i}>
                                                    <div className="row" >
                                                        <div className="col-5">
                                                            ເວລາ
                                                        </div>
                                                        <div className="col-7">
                                                            <Moment format="DD/MM/YYYY">{new Date(item.datecreate)}</Moment>
                                                        </div>
                                                        <div className="col-5">
                                                            ຊື່ການຄ້າ
                                                        </div>
                                                        <div className="col-7">
                                                            {item.type == 'naptien' && 'ທີ່ນີ້'}
                                                            {item.type == 'thangcuoc' && 'ຊະນະເກມ'}
                                                            {item.type == 'datcuoc' && 'ວາງເດີມພັນກັບເກມ'}
                                                            {item.type == 'ruttien' && 'ເຕີມເງິນ'}
                                                        </div>
                                                        <div className="col-5">
                                                            ຈໍານວນເງິນ
                                                        </div>
                                                        <div className="col-7">
                                                            {item.value}
                                                        </div>
                                                        <div className="col-5">
                                                            ສະຖານະ
                                                        </div>
                                                        <div className="col-7">
                                                            {item.status == 1 && 'ຄວາມ​ສໍາ​ເລັດ'}
                                                            {item.status == 2 && 'ກຳລັງກວດສອບ'}
                                                            {item.status == 3 && 'ຍົກເລີກ'}
                                                            {item.status == 0 && 'ລໍຖ້າຢູ່'}
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </>}
                                    <div className="w-100 d-flex justify-content-center mt-3">
                                        <Pagination
                                            activePage={page}
                                            itemsCountPerPage={limit}
                                            totalItemsCount={totalRecord}
                                            pageRangeDisplayed={5}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            prevPageText="<"
                                            nextPageText=">"
                                            onChange={setPage}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AkkhorLayout>
    )
}