import { useState, useEffect } from "react";
import backgroud from "../assets/images/xingau/bg-1.jpg"
import bgtable from "../assets/images/xingau/bg-table.jpeg"
import dia from "../assets/images/baucua/dia.png"
import bat from "../assets/images/baucua/bat.png"
import mot from "../assets/images/xingau/mot.png"
import hai from "../assets/images/xingau/hai.png"
import ba from "../assets/images/xingau/ba.png"
import bon from "../assets/images/xingau/bon.png"
import nam from "../assets/images/xingau/nam.png"
import sau from "../assets/images/xingau/sau.png"
import chip10 from "../assets/images/baucua/chip10.png"
import chip20 from "../assets/images/baucua/chip20.png"
import chip50 from "../assets/images/baucua/chip50.png"
import chip100 from "../assets/images/baucua/chip100.png"
import chip200 from "../assets/images/baucua/chip200.png"
import chip500 from "../assets/images/baucua/chip500.png"
import chipx from "../assets/images/baucua/casino-chip.png"
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import AkkhorLayout from "../layout/akkhor";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { saveGameResult, saveGameResultCache, saveMyBet, saveMyMoney } from "../redux/Actions";
import parse from 'html-react-parser'

export default function XiNgauGame() {
    const { user } = useSelector((state) => state.auth);
    const { my_money } = useSelector((state) => state.save);
    const { mybet } = useSelector((state) => state.save);
    const { gameresult } = useSelector((state) => state.save);
    const { gameresultcache } = useSelector((state) => state.save);
    const dispatch = useDispatch();
    const list_item = [mot, hai, ba, bon, nam, sau]
    const list_item_money = [10, 50, 100, 500, 1000, "All in"]
    const list_chip_money = [chip10, chip50, chip100, chip500, chip200, chip20]
    const [gameStart, setGameStart] = useState(false)
    const [betMoney, setBetMoney] = useState(0)
    const [datCuocStt, setDatCuocStt] = useState(true)
    const [textCount, setTextCount] = useState('')
    const [moBat, setMoBat] = useState(false)
    const [timeLeft, setTimeLeft] = useState(null);
    const [playAgain, setPlayAgain] = useState(false);
    const [statusMoney, setStatusMoney] = useState(null);
    const [onClose, setOnClose] = useState(false);
    const [notification, setNotification] = useState([])
    const [loadKq, setLoadKq] = useState(false)
    const [totalMoneyBet, setTotalMoneyBet] = useState(0)
    const [refreshNoti, setRefreshNoti] = useState(null)
    const [socket, setSocket] = useState(null);
    const [flag, setFlags] = useState(true)

    useEffect(() => {
        const interval = setInterval(() => {
            if (new Date().getSeconds() <= 40) {
                setTextCount('ປິດຫຼັງຈາກ:')//close after
                setTimeLeft(40 - new Date().getSeconds());
                setDatCuocStt(true)
                setOnClose(false)
                setMoBat(true)
                if (new Date().getSeconds() > 20) {
                    setNotification([])
                }
            } else {
                setDatCuocStt(false)
                if (new Date().getSeconds() <= 50) {
                    setMoBat(false)
                    setOnClose(true)
                    setTextCount('ເປີດຫຼັງຈາກ:') //open after
                    setTimeLeft(50 - new Date().getSeconds());
                } else {
                    if (new Date().getSeconds() >= 50 && new Date().getSeconds() <= 55) {
                        dispatch(saveGameResult(gameresultcache.game_result))
                        setGameStart(true)
                    }
                    if (new Date().getSeconds() > 55) {
                        dispatch(saveMyBet({ 0: 0, 1: 0 }))
                        setTextCount('ເກມໃໝ່ຫຼັງຈາກ:')//new game after
                        setTimeLeft(60 - new Date().getSeconds());
                    }
                    if (new Date().getSeconds() >= 57) {
                        setFlags(true)
                        setLoadKq(!loadKq)
                        setStatusMoney(null)
                        setBetMoney(0)
                        setPlayAgain(false);
                    }
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [gameresultcache])

    useEffect(() => {
        const connectWebSocket = () => {
            // setFlag(true)
            const socketInstance = new WebSocket('wss://socket.pokvip.com');
            socketInstance.onopen = () => { };
            socketInstance.onmessage = (evt) => {
                if (evt.data) {
                    let data = evt.data
                    try {
                        data = jwtDecode(data)
                        if (data) {
                            if (data.type && data.type == 'notification_play' && data.gamename == 'xingau') {
                                setRefreshNoti(data)
                            }
                            if (data.type && data.type == 'gameresult' && data.gamename == 'xingau') {
                                loadGameResult()
                            }
                        }
                    } catch (error) {
                        // console.log(error);
                    }
                }
            };
            socketInstance.onclose = (event) => {
                setTimeout(() => {
                    connectWebSocket();
                }, 1000);
            };

            setSocket(socketInstance);
        };
        if (!socket) {
            connectWebSocket();
        }
        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [socket]);

    useEffect(() => {
        if (onClose && totalMoneyBet > 0 && user) {
            let data_insert = { user_id: user.data.id, user_bet: JSON.stringify(mybet), total_money: totalMoneyBet, user_money: my_money, game_name: 'baucua' };
            if (user) {
                Axios.post(URL_LIST_ALL + "users_bet", data_insert, user.config).then((res) => {
                }).catch((err) => {
                    console.log(err);
                });
            }
        }
    }, [onClose])


    useEffect(() => {
        if (refreshNoti) {
            let data_notification = refreshNoti
            setNotification(data_notification.title)
        }
    }, [refreshNoti])

    const loadGameResult = () =>{
        Axios.get(URL_LIST_ALL + "game_result?game_type=xingau").then((res) => {
            if (res.data.status === 'success') {
                if (res.data.data) {
                    let data = res.data.data
                    data = jwtDecode(data)
                    dispatch(saveGameResultCache(data))
                }
            }
        })
    }
    useEffect(() => {
        if (flag) {
            if (gameresult && gameresult.length === 0) {
                loadGameResult()
                setLoadKq(true)
                setFlags(false)
            } else {
                setLoadKq(true)
                setFlags(false)
                setTimeout(() => {
                    loadGameResult()
                }, 5000);
            }
        }
    }, [loadKq, flag])

    useEffect(() => {
        if (timeLeft === 0) {
            setTimeLeft(null)
        }
        if (!timeLeft) return;
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    const onBetAction = (choice) => {
        if (!playAgain) {
            let clone = { ...mybet }
            let newBetMoney = betMoney === 'All in' ? my_money : betMoney
            clone[choice] = clone[choice] + (newBetMoney)
            let total_bet_choice = clone[choice]
            let total_bet = 0
            if (newBetMoney <= my_money) {
                Object.keys(clone).forEach(function (key) {
                    if (clone[key] > 0) {
                        total_bet = total_bet + betMoney
                        let newMoney = my_money - betMoney
                        dispatch(saveMyMoney(newMoney && betMoney !== 'All in' ? newMoney : 0));
                        let dataupdate = { money: newMoney && betMoney !== 'All in' ? newMoney : 0, game_id: gameresultcache.id }
                        Axios.post(URL_LIST_ALL + "update_money", dataupdate, user.config).then((res) => {
                        }).catch((err) => {
                            console.log(err);
                        });
                    }
                })
                setTotalMoneyBet(total_bet)
                if (user) {
                    dispatch(saveMyBet(clone))
                    let data_insert = { user_id: user.data.id,user_choice:clone, choice: choice, money: total_bet_choice, game_id: gameresultcache.id }
                    if (total_bet > 0) {
                        Axios.post(URL_LIST_ALL + "users_bet_item", data_insert, user.config).then((res) => { }).catch((err) => { });
                    }
                } else {
                    confirmAlert({
                        title: 'ຄຳເຕືອນ',
                        message: 'ກະ​ລຸ​ນາ​ເຂົ້າ​ສູ່​ລະ​ບົບ !',
                        buttons: [{ label: 'OK' }]
                    });
                }
            } else {
                //not enouth money
                confirmAlert({
                    title: 'ຄຳເຕືອນ',
                    message: 'ເງິນ​ບໍ່​ພຽງ​ພໍ​!',
                    buttons: [{ label: 'OK' }]
                });
            }
        }
    }
    useEffect(() => {
        if (gameStart) {
            let rs_ketqua = gameresult;
            setTimeout(() => {
                setGameStart(false)
                setMoBat(true)
                if (rs_ketqua) {
                    let newMoney = my_money && betMoney !== 'All in' ? my_money : 0
                    let rs_number = 0
                    let totalbet = mybet[0] + mybet[1]
                    rs_ketqua.forEach((item) => {
                        rs_number = rs_number + item + 1
                    });
                    if (rs_number % 2 === 0) {
                        if (mybet[0] > 0) {
                            newMoney = newMoney + (mybet[0] * 2)
                        }
                    } else {
                        if (mybet[1] > 0) {
                            newMoney = newMoney + (mybet[1] * 2)
                        }
                    }
                    if (newMoney !== my_money) {
                        dispatch(saveMyMoney(newMoney));
                        let after_money = newMoney - my_money;
                        setStatusMoney(<span className="text-success">+{newMoney - my_money}</span>)
                        let dataupdate = { money: newMoney, after_money: after_money, game_id: gameresultcache.id }
                        if (user) {
                            Axios.post(URL_LIST_ALL + "update_money", dataupdate, user.config).then((res) => {
                            }).catch((err) => {
                                console.log(err);
                            });
                        }
                    } else {
                        if (totalbet > 0) setStatusMoney(<span className="text-danger">{"-" + totalbet}</span>)
                    }
                    setPlayAgain(true)
                }
            }, 4000);
        }
    }, [gameStart])

    return (
        <AkkhorLayout idrole="dashboard">

            <div className="container-fluid bg-xingau" style={{ backgroundImage: 'url(' + backgroud + ')' }}>
                <div className="table-xingau">
                    <div className="wrapper">
                        <img src={bgtable} />
                        <div className="outer-cai-dia">
                            <div className={'cai-dia d-flex justify-content-center align-items-center ' + (gameStart && 'laclac')} style={{ backgroundImage: 'url(' + dia + ')' }}>
                                <div className="baucua-group">
                                    {gameresult && gameresult.length > 0 ?
                                        <>
                                            <div className="w-100 d-flex justify-content-center">
                                                <div className="item bau"><img src={list_item[gameresult[0]]} alt="" /></div>
                                                <div className="item cua"><img src={list_item[gameresult[1]]} alt="" /></div>
                                            </div>
                                            <div className="w-100 d-flex justify-content-center">
                                                <div className="item ca"><img src={list_item[gameresult[2]]} alt="" /></div>
                                            </div>
                                        </> :
                                        <>
                                            <div className="w-100 d-flex justify-content-center">
                                                <div className="item bau"><img src={mot} alt="" /></div>
                                                <div className="item cua"><img src={bon} alt="" /></div>
                                            </div>
                                            <div className="w-100 d-flex justify-content-center">
                                                <div className="item ca"><img src={sau} alt="" /></div>
                                            </div>

                                        </>
                                    }
                                </div>
                                <div className={"caibat " + (moBat ? "open" : '')} style={{ backgroundImage: 'url(' + bat + ')' }}>
                                    <div className="count-down text-center">
                                        <p>{textCount}</p>
                                        <div className="w-100 d-flex justify-content-center"><span>{(timeLeft ? timeLeft : 0) + 's'}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-100 d-flex justify-content-center count-down-group">

                        </div>

                    </div>
                </div>
                {statusMoney &&
                    <div className="status-money" style={{ backgroundImage: "url(" + chipx + ")" }}>
                        <span>{statusMoney}</span>
                    </div>
                }
                <div className="row d-flex justify-content-center mt-3">
                    <div className="col-12 col-md-9 bet-group mb-2">
                        <div className="w-100 d-flex justify-content-center">
                            <button type="button" className="btn red box-shadow" onClick={() => onBetAction(0)}>
                                {mybet && mybet[0] && mybet[0] > 0 ? <p className="show_number" style={{ backgroundImage: "url(" + chipx + ")" }}>
                                    {mybet[0]}
                                </p> : ''}
                                Even
                            </button>
                            <button type="button" className="btn black box-shadow" onClick={() => onBetAction(1)}>
                                {mybet && mybet[1] && mybet[1] > 0 ? <p className="show_number" style={{ backgroundImage: "url(" + chipx + ")" }}>
                                    {mybet[1]}
                                </p> : ''}
                                Odd
                            </button>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 listmoney">
                        <div className="row">
                            {list_item_money.map((item, i) => {
                                return (
                                    <div className="col-2 item-chip" key={i}>
                                        <button type="button" className={'btn chipmoney ' + (betMoney === item ? 'choice' : '')} onClick={() => setBetMoney(item)} disabled={datCuocStt ? false : true}>
                                            <img src={list_chip_money[i]} alt="" />
                                            <span className="text">{item}</span>
                                        </button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {notification && notification.length > 0 &&
                        <div className="container-fluid notification-play">
                            <div className="row">
                                {notification.map((item, i) =>
                                    <div className="col-4 col-sm-2 item" key={i}>
                                        <div className="noti-item" key={i}>
                                            {parse(item)}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </AkkhorLayout>
    )
}