import React from 'react';
import { useSelector } from 'react-redux';
import HeaderBar from './HeaderBar';


export default function AkkhorLayout({ children, idrole, roleignone }) {
    return (<>
        <HeaderBar />
        {children}
    </>
    );
}
