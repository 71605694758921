// *Redux Imports*
import { useDispatch } from "react-redux";
import { DEALER_SHUFFLE, START_GAME } from "../redux/Actions";

const useStartGame = () => {
  const dispatch = useDispatch();

  const startGame = () => {
    dispatch(START_GAME());
    dispatch(DEALER_SHUFFLE());
  };

  return startGame;
};

export default useStartGame;
