import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import AkkhorLayout from "../layout/akkhor";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import AlertErrorForm from "../component/AlertErrorForm";
import { confirmAlert } from "react-confirm-alert";
import * as Yup from "yup";
import Spinner from "../component/Spinner";
import backgroud from "../assets/images/Bg.jpg"

export default function Profile() {
    const { user } = useSelector((state) => state.auth);
    const [userInfo, setUserInfo] = useState(null)
    const [loading, setLoading] = useState(false);
    const defaultdetail = {
        username: '', mobile: '', fullname: '', bankname: '', banknumber: '', bankaddress: '', status: 1
    }
    useEffect(() => {
        if (user) {
            Axios.get(URL_LIST_ALL + 'users/' + user.data.id, user.config).then((res) => {
                if (res.data.status === 'success') {
                    setUserInfo(res.data.data)
                } else {
                    console.log(res.data.message);
                }
            }).catch((err) => console.log(err));
        }
    }, [])
    const formik = useFormik({
        initialValues: { ...defaultdetail, ...userInfo },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            mobile: Yup.string().trim().nullable().required('Please insert your mobile phone'),
            username: Yup.string().trim().nullable().required('Please insert your username'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (user.data.id > 0) {
                Axios.put(URL_LIST_ALL + 'users/' + user.data.id, values, user.config).then(res => {
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Success',
                            message: 'Update success full',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                    setLoading(false)
                }).catch(err => {
                    setLoading(false)
                    console.log(err)
                });
            }
        },
    });

    return (
        <AkkhorLayout idrole="dashboard">
            <div className="container-fluid" style={{ backgroundImage: 'url(' + backgroud + ')', backgroundSize: "cover", backgroundPositionX: 'center', backgroundPositionY: 'center', height: "100vh" }}>
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-12 col-sm-2 mb-2">
                        </div>
                        <div className="col-12 col-sm-8">
                            <div className="card height-auto card-question mt-5">
                                <div className="card-body">
                                    <h3 className="w-100 text-center mb-3">ຂໍ້​ມູນ​ສ່ວນ​ຕົວ</h3>
                                    <div className="wrapper p-3 border rounded">
                                        <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                                            <div className="row">
                                                <div className="col-12 form-group">
                                                    <label>ຊື່ຜູ້ໃຊ້ <span className="text-danger">*</span></label>
                                                    <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("username")} autoComplete="off" readOnly />
                                                </div>
                                                <div className="col-12 form-group">
                                                    <label>ເລກໂທລະສັບ <span className="text-danger">*</span></label>
                                                    <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("mobile")} autoComplete="off" />
                                                    <AlertErrorForm formik={formik} name="mobile"></AlertErrorForm>
                                                </div>
                                                <div className="col-12 form-group">
                                                    <label>ຊື່​ເຕັມ</label>
                                                    <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("fullname")} autoComplete="off" />
                                                    <AlertErrorForm formik={formik} name="fullname"></AlertErrorForm>
                                                </div>
                                                <div className="col-12 form-group">
                                                    <label>ຊື່ທະນາຄານ</label>
                                                    <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bankname")} autoComplete="off" />
                                                    <AlertErrorForm formik={formik} name="bankname"></AlertErrorForm>
                                                </div>
                                                <div className="col-12 form-group">
                                                    <label>ໝາຍເລກທະນາຄານ</label>
                                                    <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("banknumber")} autoComplete="off" />
                                                    <AlertErrorForm formik={formik} name="banknumber"></AlertErrorForm>
                                                </div>
                                                <div className="col-12 form-group">
                                                    <label>ທີ່ຢູ່ທະນາຄານ</label>
                                                    <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("bankaddress")} autoComplete="off" />
                                                    <AlertErrorForm formik={formik} name="bankaddress"></AlertErrorForm>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center">
                                                    <button type="submit" disabled={formik.isSubmitting} className="btn col-3 btn-success">{loading ? <Spinner /> : <>ອັບເດດ</>}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AkkhorLayout>
    )
}