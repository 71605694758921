// Allows to select a state from the store.
export const selectGameType = (store) => {
  let gameType = store.save.gameType;
  if (gameType !== null)
    return gameType.charAt(0).toUpperCase() + gameType.slice(1);
};
// Dealer Selectors
export const selectMoney = (store) => store.save.my_money;
export const selectDealerCards = (store) => store.save.dealerCards;
export const selectDealerFaceDownScore = (store) =>
  store.save.dealerFaceDownScore;
export const selectDealerScore = (store) => store.save.dealerScore;
export const selectDealerTurn = (store) => store.save.dealerTurn;
export const selectDealerStanding = (store) => store.save.dealerStanding;
export const selectDealerHasNatural = (store) =>
  store.save.dealerHasNatural;
// Player Selectors
export const selectPlayerCards = (store) => store.save.playerCards;
export const selectPlayerScore = (store) => store.save.playerScore;
export const selectPlayerBet = (store) => store.save.playerBet;
export const selectPlayerInitialHit = (store) =>
  store.save.playerInitialHit;
export const selectPlayerStanding = (store) => store.save.playerStanding;
export const selectPlayerHasNatural = (store) =>
  store.save.playerHasNatural;
export const selectStreak = (store) => store.save.streak;
export const selectWallet = (store) => store.save.wallet;
export const selectWinner = (store) => store.save.winner;
export const getUser = (store) => store.save.user;
