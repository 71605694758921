import { useState, useEffect } from "react";
import backgroud from "../assets/images/baucua/bg-baucua.png"
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import AkkhorLayout from "../layout/akkhor";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { saveGameResult, saveGameResultCache, saveMyBet, saveMyMoney } from "../redux/Actions";
import { jwtDecode } from "jwt-decode";
import chip10 from "../assets/images/baucua/chip10.png"
import chip20 from "../assets/images/baucua/chip20.png"
import chip50 from "../assets/images/baucua/chip50.png"
import chip100 from "../assets/images/baucua/chip100.png"
import chip200 from "../assets/images/baucua/chip200.png"
import chip500 from "../assets/images/baucua/chip500.png"
import chipx from "../assets/images/baucua/casino-chip.png"
import parse from 'html-react-parser'

export default function XengGame() {
    const { user } = useSelector((state) => state.auth);
    const { my_money } = useSelector((state) => state.save);
    const { mybet } = useSelector((state) => state.save);
    const { gameresult } = useSelector((state) => state.save);
    const { gameresultcache } = useSelector((state) => state.save);
    const dispatch = useDispatch();
    const list_item_money = [10, 50, 100, 500, 1000, "All in"]
    const list_chip_money = [chip10, chip50, chip100, chip500, chip200, chip20]
    const [ketqua, setKetQua] = useState([20, 20, 20])
    const [stepSpin, setStepSpin] = useState(0)
    const [betMoney, setBetMoney] = useState(0)
    const [datCuocStt, setDatCuocStt] = useState(true)
    const [statusMoney, setStatusMoney] = useState(null);
    const [nohu, setNohu] = useState(null);

    const [textCount, setTextCount] = useState('')
    const [timeLeft, setTimeLeft] = useState(null);
    const [onClose, setOnClose] = useState(false);
    const [socket, setSocket] = useState(null);
    const [notification, setNotification] = useState([])
    const [refreshNoti, setRefreshNoti] = useState(null)
    const [flag, setFlags] = useState(true)
    const [flagPin, setFlagPin] = useState(false)
    const [loadKq, setLoadKq] = useState(false)
    const [totalMoneyBet, setTotalMoneyBet] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            if (new Date().getSeconds() <= 40) {
                setFlagPin(false)
                setTextCount('ປິດຫຼັງຈາກ:') //close after
                setTimeLeft(40 - new Date().getSeconds());
                setDatCuocStt(true)
                setOnClose(false)

                if (new Date().getSeconds() > 20) {
                    setNotification([])
                }
            } else {
                setDatCuocStt(false)
                if (new Date().getSeconds() <= 45) {
                    setOnClose(true)
                    setTextCount('ໝຸນຫຼັງຈາກ:') //spin after
                    setTimeLeft(45 - new Date().getSeconds());
                } else {
                    if (new Date().getSeconds() >= 45 && new Date().getSeconds() <= 47) {
                        dispatch(saveGameResult(gameresultcache.game_result))
                        setFlagPin(true)
                    }
                    if (new Date().getSeconds() > 50) {
                        setTextCount('ເກມໃໝ່ຫຼັງຈາກ:') //newgame after
                        setTimeLeft(60 - new Date().getSeconds());
                    }
                    if (new Date().getSeconds() >= 57) {
                        setFlags(true)
                        dispatch(saveMyBet({}))
                        setLoadKq(!loadKq)
                        setStatusMoney(null)
                        setBetMoney(0)
                    }
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [gameresultcache])

    useEffect(() => {
        if (flag) {
            if (gameresult.length == 0) {
                loadGameResult()
                setLoadKq(true)
                setFlags(false)
            } else {
                setTimeout(() => {
                    loadGameResult()
                }, 5000);
            }
        }
    }, [loadKq, flag])

    const loadGameResult = () =>{
        Axios.get(URL_LIST_ALL + "game_result?game_type=xeng").then((res) => {
            if (res.data.status === 'success') {
                if (res.data.data) {
                    let data = res.data.data
                    data = jwtDecode(data)
                    dispatch(saveGameResultCache(data))
                }
            }
        })
    }

    useEffect(() => {
        const connectWebSocket = () => {
            const socketInstance = new WebSocket('wss://socket.pokvip.com');
            socketInstance.onopen = () => { };
            socketInstance.onmessage = (evt) => {
                if (evt.data) {
                    let data = evt.data
                    try {
                        data = jwtDecode(data)
                        if (data) {
                            if (data.type && data.type == 'notification_play' && data.gamename == 'xeng') {
                                setRefreshNoti(data)
                            }
                            if (data.type && data.type == 'gameresult' && data.gamename == 'xeng') {
                                loadGameResult()
                            }
                        }
                        if (data.type && data.type == 'gameresult' && data.gamename == 'xeng') {
                            loadGameResult()
                        }
                    } catch (error) { }
                }
            };
            socketInstance.onclose = (event) => {
                setTimeout(() => {
                    connectWebSocket();
                }, 1000);
            };

            setSocket(socketInstance);
        };
        if (!socket) {
            connectWebSocket();
        }
        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [socket]);

    useEffect(() => {
        if (refreshNoti) {
            let data_notification = refreshNoti
            setNotification(data_notification.title)
        }
    }, [refreshNoti])

    useEffect(() => {
        if (onClose && totalMoneyBet > 0 && user) {
            let data_insert = { user_id: user.data.id, user_bet: JSON.stringify(mybet), total_money: totalMoneyBet, user_money: my_money, game_name: 'xeng', game_id: gameresultcache.id };
            Axios.post(URL_LIST_ALL + "users_bet", data_insert, user.config).then((res) => {
            }).catch((err) => { });
        }
    }, [onClose])


    const onBetAction = (choice) => {
        let clone = { ...mybet }
        const newBetMoney = betMoney === 'All in' ? my_money : betMoney
        clone[choice] = choice in clone ? clone[choice] + (newBetMoney) : newBetMoney
        let total_bet_choice = clone[choice]
        let total_bet = 0
        if (newBetMoney <= my_money) {
            Object.keys(clone).forEach(function (key) {
                if (clone && key in clone && clone[key] > 0) {
                    total_bet = total_bet + betMoney
                    let newMoney = my_money - betMoney
                    dispatch(saveMyMoney(newMoney && betMoney !== 'All in' ? newMoney : 0));
                    let dataupdate = { money: newMoney && betMoney !== 'All in' ? newMoney : 0, game_id: gameresultcache.id }
                    Axios.post(URL_LIST_ALL + "update_money", dataupdate, user.config).then((res) => {
                    }).catch((err) => {
                        console.log(err);
                    });
                }
            })
            setTotalMoneyBet(total_bet)
            if (user) {
                dispatch(saveMyBet(clone))
                let data_insert = { user_id: user.data.id,user_choice:clone, choice: choice, money: total_bet_choice, game_id: gameresultcache.id }
                if (total_bet > 0) {
                    Axios.post(URL_LIST_ALL + "users_bet_item", data_insert, user.config).then((res) => { }).catch((err) => { });
                }
            } else {
                confirmAlert({
                    title: 'ຄຳເຕືອນ',
                    message: 'ກະ​ລຸ​ນາ​ເຂົ້າ​ສູ່​ລະ​ບົບ !',
                    buttons: [{ label: 'OK' }]
                });
            }
        } else {
             //not enouth money
             confirmAlert({
                title: 'ຄຳເຕືອນ',
                message: 'ເງິນ​ບໍ່​ພຽງ​ພໍ​!',
                buttons: [{ label: 'OK' }]
            });
        }
    }

    useEffect(() => {
        if (flagPin) {
            setStepSpin(1)
            setStatusMoney(null)
            setNohu(null)
            setDatCuocStt(false)
        }
    }, [flagPin])

    const onResult = (rs_ketqua) => {
        if (ketqua) {
            let newMoney = my_money && betMoney !== 'All in' ? my_money : 0
            let xMo = 5
            if (rs_ketqua[0] == rs_ketqua[1] && rs_ketqua[0] == rs_ketqua[2]) {
                xMo = 10
                setNohu(<span className="text-success">X{xMo}</span>)
            }
            let totalBet = 0
            rs_ketqua.forEach((item) => {
                if (mybet && mybet[item]) {
                    newMoney = newMoney + (mybet[item] * xMo)
                }
            });
            // console.log('mymoney',my_money);
            // console.log('newMoney',newMoney);
            // console.log('totalBet',totalMoneyBet);

            if (newMoney !== my_money) {
                dispatch(saveMyMoney(newMoney));
                let after_money = newMoney - my_money;
                setStatusMoney(<span className="text-success">+{newMoney - my_money}</span>)
                let dataupdate = { money: newMoney, after_money: after_money, game_id: gameresultcache.id }
                Axios.post(URL_LIST_ALL + "update_money", dataupdate, user.config).then((res) => {
                }).catch((err) => {
                    console.log(err);
                });
            } else {
                if (totalMoneyBet > 0) {
                    setStatusMoney(<span className="text-danger">{"-" + totalMoneyBet}</span>)
                }
            }
            dispatch(saveMyBet({}))
            setDatCuocStt(true)
            setTotalMoneyBet(0)
        }
    }
    useEffect(() => {
        setTimeout(() => {
            let counter = stepSpin
            if (counter > 0 && counter <= 30) {
                let render_ketqua = [Math.floor(Math.random() * 20) + 1, Math.floor(Math.random() * 20) + 1, Math.floor(Math.random() * 20) + 1]
                if (counter == 30) {
                    render_ketqua = gameresultcache.game_result
                    setKetQua(render_ketqua)
                    onResult(render_ketqua)
                } else {
                    setKetQua(render_ketqua)
                    counter = counter + 1
                    setStepSpin(counter)
                }
            }
        }, 100);
    }, [stepSpin])
    return (
        <AkkhorLayout idrole="dashboard">
            <div className="container-fluid bg-baucua xeng-game" style={{ backgroundImage: 'url(' + backgroud + ')' }}>
                <div className="w-100 d-flex justify-content-center">
                    <div className="ban-xeng">
                        <div className="list-bet-fluid top">
                            {[...Array(5)].map((item, i) =>
                                <div className="item-banphim" key={i}>
                                    <button type="button" className="btn black box-shadow" onClick={() => onBetAction(i)} disabled={datCuocStt ? false : true}>
                                        {mybet && mybet[i] && mybet[i] > 0 ? <span className="show_number">{mybet[i]}</span> : ''}
                                        <img src={"/images/fruit/fruit-" + (i) + ".png"} alt="" />
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="list-bet-fluid right">
                            <div className="wraper">
                                {[...Array(5)].map((item, i) =>
                                    <div className="item-banphim" key={i}>
                                        <button type="button" className="btn black box-shadow" onClick={() => onBetAction(i + 5)} disabled={datCuocStt ? false : true}>
                                            {mybet && mybet[i + 5] && mybet[i + 5] > 0 ? <span className="show_number">{mybet[i + 5]}</span> : ''}
                                            <img src={"/images/fruit/fruit-" + (i + 5) + ".png"} alt="" />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="list-bet-fluid left">
                            <div className="wraper">
                                {[...Array(5)].map((item, i) =>
                                    <div className="item-banphim" key={i}>
                                        <button type="button" className="btn black box-shadow" onClick={() => onBetAction(i + 15)} disabled={datCuocStt ? false : true}>
                                            {mybet && mybet[i + 15] && mybet[i + 15] > 0 ? <span className="show_number">{mybet[i + 15]}</span> : ''}
                                            <img src={"/images/fruit/fruit-" + (i + 15) + ".png"} alt="" />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="list-item-xeng">
                            <div className="row">
                                {ketqua && ketqua.length > 0 ? ketqua.map((item, i) => {
                                    return <div className="col-4 item-xeng" key={i}>
                                        <div className="item-wraper">
                                            <img src={"/images/fruit/fruit-" + item + ".png"} alt="" />
                                        </div>
                                    </div>
                                })
                                    :
                                    <>
                                        <div className="col-4 item-xeng">
                                            <div className="item-wraper">
                                                <img src={"/images/fruit/fruit-20.png"} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-4 item-xeng">
                                            <div className="item-wraper">
                                                <img src={"/images/fruit/fruit-20.png"} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-4 item-xeng">
                                            <div className="item-wraper">
                                                <img src={"/images/fruit/fruit-20.png"} alt="" />
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>

                        <div className="list-bet-fluid bottom">
                            {[...Array(5)].map((item, i) =>
                                <div className="item-banphim" key={i}>
                                    <button type="button" className="btn black box-shadow" onClick={() => onBetAction(i + 10)} disabled={datCuocStt ? false : true}>
                                        {mybet && mybet[i + 10] && mybet[i + 10] > 0 ? <span className="show_number">{mybet[i + 10]}</span> : ''}
                                        <img src={"/images/fruit/fruit-" + (i + 10) + ".png"} alt="" />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {statusMoney &&
                    <div className="status-money" style={{ backgroundImage: "url(" + chipx + ")" }}>
                        <span>{statusMoney}</span>
                    </div>
                }
                {nohu &&
                    <div className="status-nohu" style={{ backgroundImage: "url(" + chipx + ")" }}>
                        <span>{nohu}</span>
                    </div>
                }
                <div className="w-100 d-flex justify-content-center">
                    <div className="wraper-money">

                        <div className="w-100 d-flex justify-content-center">
                            <div className="count-down text-center">
                                <p>{textCount}</p>
                                <div className="w-100 d-flex justify-content-center"><span>{(timeLeft ? timeLeft : 0) + 's'}</span></div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="w-100 d-flex justify-content-center listmoney">
                    <div className="wraper-money">
                        {list_item_money.map((item, i) => {
                            return (
                                <div className="item-chip" key={i}>
                                    <button type="button" className={'btn chipmoney ' + (betMoney === item ? 'choice' : '')} onClick={() => setBetMoney(item)} disabled={datCuocStt ? false : true}>
                                        <img src={list_chip_money[i]} alt="" />
                                        <span className="text">{item}</span>
                                    </button>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {notification && notification.length > 0 &&
                    <div className="container-fluid notification-play">
                        <div className="row">
                            {notification.map((item, i) =>
                                <div className="col-4 col-sm-2 item" key={i}>
                                    <div className="noti-item" key={i}>
                                        {parse(item)}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                }
            </div>
        </AkkhorLayout>
    )
}