import React from "react";
import AkkhorLayout from "../layout/akkhor";
import baucua from "../assets/images/baucua.png"
import xidach from "../assets/images/xidach.png"
import xeng from "../assets/images/xeng.png"
import luckyspin from "../assets/images/sicbo.png"
import backgroud from "../assets/images/Bg.jpg"
import { useSelector } from "react-redux";

export default function HomePage() {
  const { my_money } = useSelector((state) => state.save);
  return (
    <AkkhorLayout idrole="dashboard" className="homepage">
      <div className="container-fluid" style={{ backgroundImage: 'url(' + backgroud + ')',backgroundSize:"cover",backgroundPositionX:'center',backgroundPositionY:'center' }}>
        <div className="container list-game">
          <div className="row">
            <div className="col-6 col-sm-6 col-lg-3 item-game mt-5 d-flex justify-content-center">
              <a className="wrapper" href="bau-cua">
                <div className="img-thumb w-100 d-flex justify-content-center">
                  <img src={baucua} alt="" className="box-shadow"/>
                </div>
                <h3 className="title w-100 text-center mt-1 text-shadow">Gourd crab fish</h3>
              </a>
            </div>
            <div className="col-6 col-sm-6 col-lg-3 item-game mt-5 d-flex justify-content-center">
              <a className="wrapper" href="/xi-dach">
                <div className="img-thumb w-100 d-flex justify-content-center">
                  <img src={xidach} alt="" className="box-shadow"/>
                </div>
                <h3 className="title w-100 text-center mt-1 text-shadow">Black jack</h3>
              </a>
            </div>
            <div className="col-6 col-sm-6 col-lg-3 item-game mt-5 d-flex justify-content-center">
              <a className="wrapper" href="/xeng">
                <div className="img-thumb w-100 d-flex justify-content-center">
                  <img src={xeng} alt="" className="box-shadow"/>
                </div>
                <h3 className="title w-100 text-center mt-1 text-shadow">Fruit machine</h3>
              </a>
            </div>
            <div className="col-6 col-sm-6 col-lg-3 item-game mt-5 d-flex justify-content-center">
              <a className="wrapper" href="/xingau">
                <div className="img-thumb w-100 d-flex justify-content-center">
                  <img src={luckyspin} alt="" className="box-shadow"/>
                </div>
                <h3 className="title w-100 text-center mt-1 text-shadow">Sic bo</h3>
              </a>
            </div>
          </div>
        </div>
      </div>
    </AkkhorLayout>
  );
}
